<template>
  <div class="container">
    <div class="detail-nav">
      <img src="../assets/arrow-left.png" class="icon" @click="goBack" alt="" />
      <div class="title">填写信息</div>
    </div>
    <div class="detail-main">
      <div class="detail-content">
        <div class="list-input">
          <div class="title">学校名称*</div>
          <div>
           <el-input v-model="schoolName" placeholder="请输入学校名称"></el-input>
          </div>
        </div>
        <div class="list-input">
          <div class="title">学校地址</div>
          <el-input v-model="schoolAddress" placeholder="请输入地址"></el-input>
        </div>
        <div class="list-input">
          <div class="title">管理员姓名*</div>
          <el-input v-model="adminName" placeholder="请输入管理员姓名"></el-input>
        </div>
        <div class="list-input">
          <div class="title">管理员手机*</div>
          <el-input v-model="adminPhone" placeholder="请输入管理员手机"></el-input>
        </div>
        <div class="list-input">
          <div class="title">推荐人邀请码</div>
          <el-input v-model="inviteCode" placeholder="请输入业务经理邀请码"></el-input>
        </div>
      </div>
      <div class="button-create">
      <div class="button" @click='goCreate'>确定</div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      schoolName:'',
      schoolAddress:'',
      adminName:'',
      adminPhone:'',
      inviteCode:''
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goCreate(){}
  },
};
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  text-align: left;
  .detail-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 0;
    box-sizing: border-box;
    .icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 15px;
    }
    .title {
      font-weight: bold;
    }
  }
  .detail-main {
    padding: 12px 15px;
    box-sizing: border-box;
    .detail-content{
      .list-input{
        margin-top:10px;
        .el-input{
          margin-top:5px
        }
      }
    }
  }
  .button-create {
    width: 100%;
    margin-top: 25px;
    padding: 0 50px;
    box-sizing: border-box;

    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      background: rgba(30, 128, 255, 0.05);
      border: 1px solid rgba(30, 128, 255, 0.3);
      border-radius: 4px;
      color: #007fff;
      font-weight: 400;
      height: 40px;
    }
  }
}
</style>
