<template>
  <div class="home">
    <div class="header-container">
      <div class="home-header">
        <div class="header-left">
          <div class="username">笑笑老师</div>
          <div class="phone">13970942044</div>
        </div>
        <div class="header-right">
          <img class="avatar" @click='changeRole' src="../assets/default-avatar.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="list-container">
      <div class="list-content">
        <div class="list-header">
          <div class="left">小（1）班</div>
          <div class="right" style="color:#007fff;text-decoration: underline">展示活码</div>
        </div>
        <div class="list-bottom">
          <div class="bottom-left">活跃人数：12</div>
          <div class="bottom-right" @click='goClassInfo'>查看详情</div>
        </div>
      </div>
    </div>
    <div class="list-container">
      <div class="list-content">
        <div class="list-header">
          <div class="left">小（2）班</div>
          <div class="right" style="color:#007fff;text-decoration: underline">展示活码</div>
        </div>
        <div class="list-bottom">
          <div class="bottom-left">活跃人数：18</div>
          <div class="bottom-right" @click='goClassInfo'>查看详情</div>
        </div>
      </div>
    </div>
      <div class="list-container">
      <div class="list-content">
        <div class="list-header">
          <div class="left">小（3）班</div>
          <div class="right" style="color:#007fff;text-decoration: underline">展示活码</div>
        </div>
        <div class="list-bottom">
          <div class="bottom-left">活跃人数：0</div>
          <div class="bottom-right" @click='goClassInfo'>查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  methods:{
    goCreate(){
      this.$router.push('/createSchoolInfo')
    },
    goClassInfo(){
      this.$router.push('/classInfo')
    },
    changeRole(){
      this.$router.push('/')
    }
  }
};
</script>
<style lang="less">
.home {
  width: 100%;
  .header-container {
    width: 100%;
    padding: 16px 6px;
    box-sizing: border-box;
    .home-header {
      width: 100%;
      padding: 12px 22px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #eaf2ff;
      border-radius: 6px;
      .header-left {
        .username {
          font-size: 17px;
          color: #000;
        }
        .phone {
          font-size: 17px;
          color: #000;
          margin-top: 12px;
        }
      }
      .header-right {
        .avatar {
          width: 55px;
          height: 55px;
          border-radius: 50%;
        }
      }
    }
  }
  .list-container {
    width: 100%;
    padding: 15px 6px;
    box-sizing: border-box;
    .list-content {
      width: 100%;
      padding: 22px 12px;
      box-sizing: border-box;
      border: 1px solid #ddd;
      border-radius: 6px;
      .list-header {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        display: flex;
        justify-content: space-between;
      }
      .list-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        color: #1e80ff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .button-create {
    width: 100%;
    margin-top: 15px;
    padding: 0 50px;
    box-sizing: border-box;

    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      background: rgba(30, 128, 255, 0.05);
      border: 1px solid rgba(30, 128, 255, 0.3);
      border-radius: 4px;
      color: #007fff;
      font-weight: 400;
      height: 40px;
    }
  }
}
</style>