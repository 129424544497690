<template>
  <div class="container">
    <div class="detail-nav">
      <img src="../assets/arrow-left.png" class="icon" @click="goBack" alt="" />
      <div class="title">学校信息</div>
    </div>
    <div class="detail-main">
      <div class="detail-content">
        <div class="school-info">
          <div class="school-name">江西广电幼儿园</div>
          <div class="class-num">版及数量：10</div>
          <div class="active-num">活跃人数：185</div>
        </div>
      </div>
      <div class="class-info">
        <div class="class-list">
          <div class="class-left">
            <div class="class-name">小(1)班</div>
            <div class="admin">管理员：笑笑老师</div>
          </div>
          <div class="class-right">
            <div class="active-num">活跃人数13人</div>
            <div class="show-code" style="color:#007fff;text-decoration: underline">展示活码</div>
          </div>
        </div>
          <div class="class-list">
          <div class="class-left">
            <div class="class-name">小(2)班</div>
            <div class="admin">管理员：笑笑老师</div>
          </div>
          <div class="class-right">
            <div class="active-num">活跃人数13人</div>
            <div class="show-code" style="color:#007fff;text-decoration: underline">展示活码</div>
          </div>
        </div>
          <div class="class-list">
          <div class="class-left">
            <div class="class-name">中（1）班</div>
            <div class="admin">管理员：笑笑老师</div>
          </div>
          <div class="class-right">
            <div class="active-num">活跃人数13人</div>
            <div class="show-code" style="color:#007fff;text-decoration: underline">展示活码</div>
          </div>
        </div>
      </div>
    </div>
       <div class="button-create">
      <div class="button" @click='goCreate'>创建班级</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goCreate(){
      this.$router.push('/createClassInfo')
    }
  },
};
</script>
<style lang='less' scoped>
.container {
  width: 100%;
  text-align: left;
  .detail-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 0;
    box-sizing: border-box;
    .icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 15px;
    }
    .title {
      font-weight: bold;
    }
  }
  .detail-main {
    padding: 12px 15px;
    box-sizing: border-box;
    .school-info {
      width: 100%;
      border-radius: 6px;
      border: 3px solid rgba(30, 128, 255, 0.3);
      padding: 12px;
      box-sizing: border-box;
      .school-name {
        font-size: 18px;
      }
      .class-num {
        margin-top: 6px;
      }
      .active-num {
        margin-top: 6px;
      }
    }
    .class-info {
      width: 100%;
      margin-top:16px;
      padding: 15px 12px;
      box-sizing: border-box;
      border-radius: 6px;
      border: 3px solid rgba(30, 128, 255, 0.3);
      .class-list {
        margin-top:8px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
       
      }
    }
  }
    .button-create {
    width: 100%;
    margin-top: 15px;
    padding: 0 50px;
    box-sizing: border-box;

    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      background: rgba(30, 128, 255, 0.05);
      border: 1px solid rgba(30, 128, 255, 0.3);
      border-radius: 4px;
      color: #007fff;
      font-weight: 400;
      height: 40px;
    }
  }
}
</style>