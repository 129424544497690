var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"detail-nav"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/logo.png"),"alt":""}}),_vm._m(0),_c('div',{staticClass:"back-btn",on:{"click":_vm.goBack}},[_vm._v("返回")])]),_c('div',{staticClass:"detail-main"},[_vm._m(1),_c('div',{staticClass:"main-content"},_vm._l((_vm.list),function(item,idx){return _c('div',{key:idx,staticClass:"list",class:!item.status 
            ? 'out-line'
            : item.status.includes('低')
            ? 'low-temp'
            : item.status.includes('低') ?
            'high-temp'
            :item.temperature<32?
            'low-temp':item.temperature>37?
            'high-temp': item.humidity>90?
            'change-temp':''},[_c('div',{staticClass:"list-header"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":require("../assets/default-avatar.jpg"),"alt":""}}),_c('div',{staticClass:"username"},[_vm._v(_vm._s(item.username.substring(0, 4)))])])]),_c('div',{staticClass:"temp"},[_vm._v(_vm._s(item.temperature)+"°C")]),_c('div',{staticClass:"humidity"},[_vm._v(_vm._s(item.humidity>90?'有汗':'无汗'))])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-class"},[_c('div',{staticClass:"title"},[_vm._v("知冷暖幼儿穿衣监护器")]),_c('div',{staticClass:"class"},[_vm._v("南昌市幼儿园-小（1）班")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-header"},[_c('div',{staticClass:"log-info"},[_vm._v("查看日志")]),_c('div',{staticClass:"type-list"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"back-color"}),_c('div',{staticClass:"title"},[_vm._v("正常")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"back-color",staticStyle:{"background":"#00d1f1"}}),_c('div',{staticClass:"title"},[_vm._v("加衣")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"back-color",staticStyle:{"background":"#f91d14"}}),_c('div',{staticClass:"title"},[_vm._v("减衣")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"back-color",staticStyle:{"background":"orange"}}),_c('div',{staticClass:"title"},[_vm._v("换衣")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"back-color",staticStyle:{"background":"grey"}}),_c('div',{staticClass:"title"},[_vm._v("离线")])])])])
}]

export { render, staticRenderFns }