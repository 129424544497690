import axios from 'axios'
import { Message } from 'element-ui'



 //let baseApi = '/api'
 let baseApi = 'https://cc.zhilengnuan.cn/'

const serves = axios.create({
    baseURL: baseApi,
    timeout: 50000
})

// 设置请求发送之前的拦截器
serves.interceptors.request.use(config => {
    // 权限专用接口地址
    // if (config.url === '/menu/findPermissionsByUserId') {
    //     config.baseURL = meunConfigURL
    // }

    config.headers['Access-Control-Allow-Origin'] = '*'
    let tokens = sessionStorage.tokens
    if (tokens) {
        // config.headers['Authorization'] = 'Bearer ' + tokens
        config.headers['X-Access-Token'] = tokens
    }



    return config
}, err => Promise.reject(err))

// 设置响应接受拦截器
serves.interceptors.response.use(res => {

    // 设置接受数据之后，做什么处理
    if (res.data.code === 50000) {
        Message.error(res.data.data)
    }
    if (res.data.code === 200) {
        return Promise.resolve(res.data)
    } else if (res.data.code === 600) {
        return Promise.resolve(res.data)
    } else if (res.data.code === -1) {
        return Promise.reject(res.data.msg)
    } else {
        window.$EndLoading && window.$EndLoading()
        if (res.data.msg) {
            Message.error(res.data.msg || '出错啦！请您稍后重试')
            return Promise.reject(res.data.msg)
        } else {
            return Promise.resolve(res.data)
        }
    }
}, err => {
    window.$EndLoading && window.$EndLoading()
    // 判断请求异常信息中是否含有超时timeeout字符串
    console.log(err, 888)
    if (err.message.includes('timeout')) {
        console.log('错误回调', err)
        Message.error('网络超时')
    }
    if (err.message.includes('Network Error')) {
        console.log('错误回调', err)
        Message.error('服务端未启动，或网络连接错误')
    } else if (err.message.includes('500')) {
        // window.location.href = '/login'
    }
    return Promise.reject(err)
})

// 将serves抛出去
export default serves
