import request from '@/utils/request.js'



/**
 *  学校列表
 * @returns {*}
 */
 export function getSchoolList(params) {
  return request({
      url: `/znl-boot/base/xxSchool/rootList`,
      method: 'get',
      params
  })
}


/**
 *  登录
 * @returns {*}
 */
 export function loginByPwd(data) {
    return request({
        url: `/znl-boot/sys/mLogin`,
        method: 'post',
        data
    })
}


/**
 * 班级设备
 * @returns {*}
 */
 export function getClassDeviceList(data) {
    return request({
        url: `/znl-boot/base/xxGrades/getSchoolDeviceValueList`,
        method: 'post',
        data
    })
}
