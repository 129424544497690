<template>
    <div class="
        page-module
        login-container
        backgroundCover
        flex
        width_100
        height_100
      ">
      <div class="title">知冷暖后台</div>
      <div class="login-panel" @keyup.enter="onSubmit">
        <div class="head-text align_center"></div>
        <el-form class="login-form" size="medium" :model="form" :rules="rules" ref="form">
          <el-form-item prop="username">
            <el-input placeholder="请输入手机号：" autofocus v-model="form.username" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" placeholder="请输入密码：" v-model="form.password" />
          </el-form-item>
          <el-form-item class="login-in">
            <el-button style="margin-top:30px" type="primary" class="width_100" @click="onSubmit" :loading="submitLoad">
              登录
            </el-button>
          </el-form-item>
        </el-form>
        <!-- <div class="change-pwd" @click="changePwd">修改密码</div> -->
      </div>
    </div>
  </template>
  <script type="text/javascript">
  import { loginByPwd, } from "@/api/index.js";
  
  export default {
    name: "Login",
    data() {
      return {
        form: {
          username: "admin",
          password: "123456",
        },
        rules: {
          username: [
            { required: true, message: "请输入手机号！", trigger: "blur" },
          ],
          password: [
            { required: true, message: "请输入账户密码！", trigger: "blur" },
          ],
        },
        submitLoad: false,
      };
    },
    created() {

    },
  mounted(){
    this.onSubmit()
  },
    methods: {
      getUrlCode() {
        // 截取url中的code方法
        var url = location.search;
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
          var str = url.substr(1);
          var strs = str.split("&");
          for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
          }
        }
        return theRequest;
      },
  
      changePwd() {
        this.$router.push("/changePwd");
      },
      
      onSubmit() {
        if (this.form.username === "") {
          this.$message.warning("请输入手机号！");
          return;
        }
        // if (this.form.password === "") {
        //   this.$message.warning("请输入密码！");
        //   return;
        // }
        loginByPwd(this.form).then((res) => {
          if (res.code === 200) {
            sessionStorage.tokens = res.result.token;
            sessionStorage.userInfo = JSON.stringify(res.result.userInfo);
            this.$message.success("登录成功！");
            this.$router.push('/classInfo')
           
          } else {
            this.$message.error(res);
          }
        });
        // this.$router.push('/writeDocument')
      },
    },
  };
  </script>
  <style lang="less" scoped>
  /deep/ .el-button--medium {
    padding: 10px 120px;
  }
  
  .login-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: url(../assets/zln_bg.jpeg);
  
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-top: -180px;
    }
  
    .login-panel {
      padding: 25px 15px;
      width: 350px;
      border-radius: 3px;
      background-color: #fff;
      margin-top: 50px;
    }
  
    .head-text {
      margin-bottom: 25px;
    }
  
    .login-in {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  
    .change-pwd {
      color: #999;
      font-size: 12px;
      cursor: pointer;
    }
  }
  </style>