<template>
  <div class="container">
    <div class="detail-nav">
      <img src="../assets/logo.png" class="icon" alt="" />
      <div class="title-class">
        <div class="title">知冷暖幼儿穿衣监护器</div>
        <div class="class">南昌市幼儿园-小（1）班</div>
      </div>
      <div class="back-btn" @click="goBack">返回</div>
    </div>
    <div class="detail-main">
      <div class="main-header">
        <div class="log-info">查看日志</div>
        <div class="type-list">
          <div class="list">
            <div class="back-color"></div>
            <div class="title">正常</div>
          </div>
          <div class="list">
            <div class="back-color" style="background: #00d1f1"></div>
            <div class="title">加衣</div>
          </div>
          <div class="list">
            <div class="back-color" style="background: #f91d14"></div>
            <div class="title">减衣</div>
          </div>
          <div class="list">
            <div class="back-color" style="background: orange"></div>
            <div class="title">换衣</div>
          </div>
          <div class="list">
            <div class="back-color" style="background: grey"></div>
            <div class="title">离线</div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <div
          class="list"
          v-for="(item, idx) in list"
          :key="idx"
          :class="
            !item.status 
              ? 'out-line'
              : item.status.includes('低')
              ? 'low-temp'
              : item.status.includes('低') ?
              'high-temp'
              :item.temperature<32?
              'low-temp':item.temperature>37?
              'high-temp': item.humidity>90?
              'change-temp':''
          "
        >
          <div class="list-header">
            <div class="avatar">
              <img src="../assets/default-avatar.jpg" alt="" />
              <div class="username">{{ item.username.substring(0, 4) }}</div>
            </div>
          </div>
          <div class="temp">{{ item.temperature }}°C</div>
          <div class="humidity">{{ item.humidity>90?'有汗':'无汗' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassDeviceList } from "@/api/index.js";
export default {
  data() {
    return {
      list: [
        // {
        //   id: 0,
        //   username: "吴文轩",
        //   temp: 36.5,
        //   humidity: "无汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 1,
        //   username: "李铭",
        //   temp: 37.6,
        //   humidity: "有汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 2,
        //   username: "王浩然",
        //   temp: 29.5,
        //   humidity: "无汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 3,
        //   username: "陈莉莉",
        //   temp: 30.8,
        //   humidity: "无汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 0,
        //   username: "吴文轩",
        //   temp: 36.5,
        //   humidity: "无汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 1,
        //   username: "李铭",
        //   temp: 37.6,
        //   humidity: "有汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 2,
        //   username: "王浩然",
        //   temp: 29.5,
        //   humidity: "无汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 3,
        //   username: "陈莉莉",
        //   temp: 30.8,
        //   humidity: "无汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 0,
        //   username: "吴文轩",
        //   temp: 36.5,
        //   humidity: "无汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 1,
        //   username: "李铭",
        //   temp: 37.6,
        //   humidity: "有汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 2,
        //   username: "王浩然",
        //   temp: 29.5,
        //   humidity: "无汗",
        //   isOnline: "1",
        // },
        // {
        //   id: 3,
        //   username: "陈莉莉",
        //   temp: 30.8,
        //   humidity: "无汗",
        //   isOnline: "1",
        // },
        //  { id: 0, username: "吴文轩", temp: 36.5, humidity: "无汗" ,isOnline:'1'},
        // { id: 1, username: "李铭", temp: 37.6, humidity: "有汗",isOnline:'1' },
        // { id: 3, username: "陈莉莉", temp: 30.8, humidity: "无汗",isOnline:'1' },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getClassDeviceList({ gradesId: "1639182589584105473" }).then((res) => {
        if (res.code === 200) {
          this.list = res.result.list;
        }
      });
    },
    searchStatus(e) {
      if (!e) {
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang='less' scoped>
.container {
  width: 100%;
  text-align: left;
  .detail-nav {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 15px 15px;
    box-sizing: border-box;
    background: rgba(30, 128, 255, 0.05);
    .icon {
      width: 30px;
      height: 30px;
      // position: absolute;
      // left: 15px;
    }
    .back-btn {
      position: absolute;
      right: 15px;
    }
    .title-class {
      font-size: 16px;
      margin-left: 12px;
      .title {
        font-weight: bold;
        color: blue;
      }
      .class {
        font-size: 12px;
      }
    }
  }
  .detail-main {
    padding: 12px 12px;
    box-sizing: border-box;
    .main-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .log-info {
        width: 80px;
        text-align: center;
        line-height: 26px;
        height: 26px;
        border-radius: 3px;
        background: #00d1f1;
        color: #fff;
        font-size: 14px;
        flex-shrink: 0;
      }
      .type-list {
        display: flex;
        align-items: center;
        padding-left: 50px;
        // width: 200px;
        flex-wrap: wrap;
        .list {
          display: flex;
          align-items: center;
          font-size: 12px;
          margin-right: 8px;
          margin-top: 6px;
          .back-color {
            width: 35px;
            height: 14px;
            background: #16af19;
            border-radius: 3px;
          }
          .title {
            margin-left: 4px;
          }
        }
      }
    }
    .main-content {
      margin-top: 26px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .list {
        width: 73px;
        height: 70px;
        border-radius: 5px;
        border: 3px solid #16af19;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        font-size: 10px;
        color: #16af19;
        margin-top: 10px;

        .list-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0px 1px 0 4px;
          box-sizing: border-box;
          .avatar {
            display: flex;
            align-items: center;
            .username {
              margin-left: 5px;
            }
            img {
              width: 15px;
              height: 15px;
              border-radius: 50%;
            }
          }
        }
        .temp {
          margin-top: 5px;
        }
        .humidity {
          margin-top: 0px;
        }
      }
      .high-temp {
        color: #f91d14;
        border: 3px solid #f91d14;
      }
      .low-temp {
        color: #00d1f1;
        border: 3px solid #00d1f1;
      }
      .out-line {
        color: grey;
        border: 3px solid grey;
      }
        .change-temp {
        color: orange;
        border: 3px solid orange;
      }
    }
  }
}
</style>